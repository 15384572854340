import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/images/common/icon.png'


const _hoisted_1 = { class: "home-view-wrapper" }
const _hoisted_2 = { class: "title-box" }
const _hoisted_3 = { class: "icp-container" }

import MLoading from "@/components/MLoading.vue";
import Icp from "@/components/IcpComp.vue";
import CateData from '@/components/CateData.vue';

import { Haa, OeReportType, OeReportSubType } from "@haa/fwk-h5/lib/index";
import { onMounted, ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

Haa.util().useLifeCycle();

const loading = ref(false);

const catelist = ['hot', 'newest', 'more'];
const renderList = ref([]);
const onLoad = () => {
  if (loading.value) return;
  loading.value = true;
  let cate = catelist.shift();
  if (cate) renderList.value.push(cate);
  setTimeout(() => {
    loading.value = false;
  }, 1200);
};

const appName = ref<string>(APP_NAME);
const mainColor = ref<string>(MAIN_COLOR);

return (_ctx: any,_cache: any) => {
  const _component_van_list = _resolveComponent("van-list")!
  const _directive_slide = _resolveDirective("slide")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
      _createElementVNode("span", {
        style: _normalizeStyle({background: mainColor.value,'-webkit-background-clip': 'text'})
      }, _toDisplayString(appName.value), 5)
    ]),
    _createVNode(_component_van_list, { onLoad: onLoad }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(renderList.value, (item, index) => {
          return (_openBlock(), _createBlock(CateData, {
            category: item,
            banner: index == 0
          }, null, 8, ["category", "banner"]))
        }), 256))
      ]),
      _: 1
    }),
    (loading.value)
      ? (_openBlock(), _createBlock(MLoading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(Icp)
    ])
  ])), [
    [_directive_slide]
  ])
}
}

})