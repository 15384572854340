<script setup lang="ts">
import MLoading from "@/components/MLoading.vue";
import Icp from "@/components/IcpComp.vue";
import CateData from '@/components/CateData.vue';

import { Haa, OeReportType, OeReportSubType } from "@haa/fwk-h5/lib/index";
Haa.util().useLifeCycle();

import { onMounted, ref } from "vue";
const loading = ref(false);

const catelist = ['hot', 'newest', 'more'];
const renderList = ref([]);
const onLoad = () => {
  if (loading.value) return;
  loading.value = true;
  let cate = catelist.shift();
  if (cate) renderList.value.push(cate);
  setTimeout(() => {
    loading.value = false;
  }, 1200);
};

const appName = ref<string>(APP_NAME);
const mainColor = ref<string>(MAIN_COLOR);
</script>

<template>
  <div class="home-view-wrapper" v-slide>
    <div class="title-box">
      <img src="@/assets/images/common/icon.png" />
      <span :style="{background: mainColor,'-webkit-background-clip': 'text'}">{{appName}}</span>
    </div>
    <van-list @load="onLoad">
      <CateData v-for="(item, index) in renderList" :category="item" :banner="index == 0"></CateData>
    </van-list>

    <MLoading v-if="loading"></MLoading>

    <div class="icp-container">
      <Icp></Icp>
    </div>
  </div>
</template>

<style scoped lang="less">
.home-view-wrapper {
  height: 100vh;
  overflow-y: auto;
  background-color: #fafeff;
  background-image: url("@/assets/images/common/bg_top.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 60px;
  box-sizing: border-box;
  .title-box {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 60px;
      height: 44px;
      margin-right: 10px;
    }
    span {
      color: transparent;
      font-size: 36px;
      font-weight: bold;
    }
  }
  .icp-container {
    width: 100%;
    max-width: 750px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
  }
}
</style>
